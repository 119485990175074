<template>
  <div class="container">
    <div class="toeflMenu">
      <ToeflMenu active="4" />
    </div>
    <div class="filters">
      <el-form ref="form" label-width="80px">
        <el-form-item label="資料類型">
          <el-select
            style="width: 100%"
            :value="drawChatType"
            @change="routerToDrawChatType"
          >
            <el-option :value="drawChatTypes.totalTestChart" label="考卷" />
            <el-option
              :value="drawChatTypes.separateUnlockAndScoresExamChart"
              label="批改考卷"
            />
            <el-option :value="drawChatTypes.testTypesChart" label="考卷種類" />
            <el-option
              :value="drawChatTypes.orderPaymentTotalChart"
              label="儲值金額"
            />
            <el-option
              :value="drawChatTypes.tCoinPaymentCountChart"
              label="Ｔ幣交易筆數"
            />
            <el-option
              :value="drawChatTypes.tCoinPaymentTotalChart"
              label="Ｔ幣交易金額"
            />
            <el-option :value="drawChatTypes.subsChart" label="訂閱數量" />
            <el-option
              :value="drawChatTypes.addSubsChart"
              label="新增訂閱數量"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="資料範圍">
          <el-radio-group
            v-model="dateRangeType"
            @change="
              val => {
                routerToDateRange(val);
              }
            "
          >
            <el-radio-button :label="dateRangeTypes.firstDay"
              >所有</el-radio-button
            >
            <el-radio-button :label="dateRangeTypes.year"
              >前一年</el-radio-button
            >
            <el-radio-button :label="dateRangeTypes.halfAYear"
              >前半年</el-radio-button
            >
            <el-radio-button :label="dateRangeTypes.month"
              >前一月</el-radio-button
            >
            <el-radio-button :label="dateRangeTypes.week"
              >前一週</el-radio-button
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item label="資料單位">
          <el-radio-group
            v-model="xAxisCaculateRangeType"
            @change="
              val => {
                routerToXAxisCaculateRange(val);
              }
            "
          >
            <el-radio-button :label="xAxisCaculateRangeTypes.day"
              >天</el-radio-button
            >
            <el-radio-button
              v-if="!isDailyAnalysis"
              :label="xAxisCaculateRangeTypes.week"
              >週</el-radio-button
            >
            <el-radio-button
              v-if="!isDailyAnalysis"
              :label="xAxisCaculateRangeTypes.month"
              >月</el-radio-button
            >
            <el-radio-button
              v-if="!isDailyAnalysis"
              :label="xAxisCaculateRangeTypes.year"
              >年</el-radio-button
            >
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>
    <hr />
    <div class="analyticsChart" ref="analyticsChart" />
  </div>
</template>

<script>
import moment from "moment";
import echarts from "echarts";
import ToeflMenu from "@/views/toefl/components/ToeflMenu.vue";

export default {
  metaInfo() {
    return {
      title: "Analytics - " + this.CompanyName
    };
  },
  components: {
    ToeflMenu
  },
  data() {
    return {
      dateRangeType: null,
      drawChatType: null,
      xAxisCaculateRangeType: null,
      anchorPoints: []
    };
  },
  computed: {
    drawChatTypes() {
      return {
        totalTestChart: "totalTestChart",
        separateUnlockAndScoresExamChart: "separateUnlockAndScoresExamChart",
        tCoinPaymentCountChart: "tCoinPaymentCountChart",
        tCoinPaymentTotalChart: "tCoinPaymentTotalChart",
        testTypesChart: "testTypesChart",
        orderPaymentTotalChart: "orderPaymentTotalChart",
        subsChart: "subsChart",
        addSubsChart: "addSubsChart"
      };
    },
    xAxisCaculateRangeTypes() {
      return {
        day: "day",
        week: "week",
        month: "month",
        year: "year"
      };
    },
    dateRangeTypes() {
      return {
        firstDay: "firstDay",
        year: "year",
        halfAYear: "halfAYear",
        month: "month",
        week: "week"
      };
    },
    firstDayDate() {
      return "2020-12-08";
    },
    lastYearDate() {
      return moment()
        .subtract(1, "years")
        .format("yyyy-MM-DD");
    },
    lastHalfAYearDate() {
      return moment()
        .subtract(6, "months")
        .format("yyyy-MM-DD");
    },
    lastMonthDate() {
      return moment()
        .subtract(1, "months")
        .format("yyyy-MM-DD");
    },
    lastWeekDate() {
      return moment()
        .subtract(1, "weeks")
        .format("yyyy-MM-DD");
    },
    analyses() {
      return this.$store.getters["analytics/analysesMappingArray"];
    },
    isDailyAnalysis() {
      return this.drawChatType === "subsChart";
    }
  },
  watch: {
    analyses() {
      this.getAnchorPoints();
      this.draw();
    }
  },
  async created() {
    this.drawChatType =
      this.$route.query.drawChatType || this.drawChatTypes.totalTestChart;
    this.xAxisCaculateRangeType =
      this.$route.query.xAxisCaculateRangeType ||
      this.xAxisCaculateRangeTypes.day;
    this.dateRangeType =
      this.$route.query.dateRangeType || this.dateRangeTypes.week;
    this.fetchAnalytics(this.dateRangeType);
  },
  methods: {
    routerToDrawChat(Condition) {
      if (Condition.drawChatType === "subsChart") {
        Condition["xAxisCaculateRangeType"] = "day";
      }

      this.$router.push({
        name: "ToeflAnalytics",
        query: {
          ...this.$route.query,
          ...Condition
        }
      });
    },
    routerToDrawChatType(drawChatType) {
      this.routerToDrawChat({ drawChatType });
    },
    routerToDateRange(dateRangeType) {
      this.routerToDrawChat({ dateRangeType });
    },
    routerToXAxisCaculateRange(xAxisCaculateRangeType) {
      this.routerToDrawChat({ xAxisCaculateRangeType });
    },
    fetchAnalytics(dateRangeType) {
      switch (dateRangeType) {
        case this.dateRangeTypes.firstDay:
          this.fetchAnalyticsFrom(this.firstDayDate);
          break;
        case this.dateRangeTypes.year:
          this.fetchAnalyticsFrom(this.lastYearDate);
          break;
        case this.dateRangeTypes.halfAYear:
          this.fetchAnalyticsFrom(this.lastHalfAYearDate);
          break;
        case this.dateRangeTypes.month:
          this.fetchAnalyticsFrom(this.lastMonthDate);
          break;
        case this.dateRangeTypes.week:
          this.fetchAnalyticsFrom(this.lastWeekDate);
          break;
        default:
      }
    },
    async fetchAnalyticsFrom(
      fromDate = this.lastWeekDate,
      endDate = moment().format("yyyy-MM-DD")
    ) {
      const payload = {
        start_date: fromDate,
        end_date: endDate
      };
      switch (this.drawChatType) {
        case this.drawChatTypes.totalTestChart:
        case this.drawChatTypes.separateUnlockAndScoresExamChart:
        case this.drawChatTypes.testTypesChart:
          await this.$store.dispatch("analytics/fetchAnalytics", payload);
          break;
        case this.drawChatTypes.tCoinPaymentCountChart:
        case this.drawChatTypes.tCoinPaymentTotalChart:
          await this.$store.dispatch(
            "analytics/fetchTransactionAnalytics",
            payload
          );
          break;
        case this.drawChatTypes.orderPaymentTotalChart:
          await this.$store.dispatch("analytics/fetchOrderAnalytics", payload);
          break;
        case this.drawChatTypes.subsChart:
        case this.drawChatTypes.addSubsChart:
          await this.$store.dispatch("analytics/fetchSubsAnalytics", payload);
          break;
        default:
      }
    },
    draw() {
      this.charts = echarts.init(this.$refs["analyticsChart"]);
      let chartData = [];
      switch (this.drawChatType) {
        case this.drawChatTypes.totalTestChart:
          chartData = [
            { name: "所有考卷數", key: "total_taken_number", color: "#99cde5" },
            {
              name: "非空白考卷",
              key: "answered_take_number",
              color: "#fc9294"
            },
            {
              name: "已完成考卷數",
              key: "finished_take_number",
              color: "#fce59b"
            }
          ];
          break;
        case this.drawChatTypes.separateUnlockAndScoresExamChart:
          chartData = [
            {
              name: "要求批改考卷數",
              key: "total_request_correct_number",
              color: "#99cde5"
            },
            {
              name: "簡單評語批改數",
              key: "simple_request_correct_number",
              color: "#fc9294"
            },
            {
              name: "詳細評語批改數",
              key: "vip_request_correct_number",
              color: "#fce59b"
            },
            {
              name: "分數批改數",
              key: "score_request_correct_number",
              color: "#abd795"
            }
          ];
          break;
        case this.drawChatTypes.testTypesChart:
          chartData = [
            {
              name: "所有完整模考數",
              key: "full_mock_total_taken_number",
              color: "#99cde5"
            },
            {
              name: "完成完整模考數",
              key: "full_mock_finished_taken_number",
              color: "#009de6"
            },
            {
              name: "所有快速模考數",
              key: "quick_mock_total_taken_number",
              color: "#fc9294"
            },
            {
              name: "完成快速模考數",
              key: "quick_mock_finished_taken_number",
              color: "#fc0004"
            },
            {
              name: "所有閱讀考卷數",
              key: "reading_total_taken_number",
              color: "#fce59b"
            },
            {
              name: "完成閱讀考卷數",
              key: "reading_finished_taken_number",
              color: "#ffc400"
            },
            {
              name: "所有聽力考卷數",
              key: "listening_total_taken_number",
              color: "#abd795"
            },
            {
              name: "完成聽力考卷數",
              key: "listening_finished_taken_number",
              color: "#42c700"
            },
            {
              name: "所有口說考卷數",
              key: "speaking_total_taken_number",
              color: "#9077b5"
            },
            {
              name: "完成口說考卷數",
              key: "speaking_finished_taken_number",
              color: "#4700b0"
            },
            {
              name: "所有寫作考卷數",
              key: "writing_total_taken_number",
              color: "#7a645d"
            },
            {
              name: "完成寫作考卷數",
              key: "writing_finished_taken_number",
              color: "#701a00"
            }
          ];
          break;
        case this.drawChatTypes.tCoinPaymentCountChart:
          chartData = [
            {
              name: "消費在批改考卷的筆數",
              key: "consume_request_correct_number",
              color: "#99cde5"
            },
            {
              name: "總消費 T 幣筆數",
              key: "consume_total_number",
              color: "#fc9294"
            },
            {
              name: "花錢儲值 T 幣筆數",
              key: "deposit_currency_number",
              color: "#fce59b"
            },
            {
              name: "儲值 T 幣總筆數",
              key: "deposit_total_number",
              color: "#abd795"
            }
          ];
          break;
        case this.drawChatTypes.tCoinPaymentTotalChart:
          chartData = [
            {
              name: "總消費 T 幣",
              key: "consume_total_coin",
              color: "#99cde5"
            },
            {
              name: "儲值 T 幣總額",
              key: "deposit_total_coin",
              color: "#fc9294"
            },
            {
              name: "花錢儲值 T 幣總額",
              key: "deposit_currency_coin",
              color: "#fce59b"
            }
          ];
          break;
        case this.drawChatTypes.orderPaymentTotalChart:
          chartData = [
            { name: "首次訂閱金額", key: "first_subs_price", color: "#fc9294" },
            {
              name: "持續訂閱金額",
              key: "renewal_subs_price",
              color: "#fce59b"
            },
            {
              name: "T幣金額",
              key: "coin_price",
              color: "#9B59B6"
            },
            { name: "儲值金額", key: "total_price", color: "#99cde5" }
          ];
          break;
        case this.drawChatTypes.subsChart:
          chartData = [
            { name: "首次訂閱", key: "first_time_count", color: "#fc9294" },
            { name: "連續訂閱", key: "renewal_count", color: "#fce59b" },
            { name: "總訂閱", key: "total_count", color: "#99cde5" }
          ];
          break;
        case this.drawChatTypes.addSubsChart:
          chartData = [
            { name: "首次訂閱", key: "add_first_count", color: "#fc9294" },
            { name: "連續訂閱", key: "add_renewal_count", color: "#fce59b" },
            { name: "總訂閱", key: "add_total_count", color: "#99cde5" }
          ];
          break;
        default:
      }
      const option = {
        color: chartData.map(({ color }) => color),
        legend: {
          data: chartData.map(({ name }) => name)
        },
        series: chartData.map(datum =>
          this.getSeriesData(datum.name, datum.key)
        ),
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985"
            }
          }
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: this.getxAxiosData()
          }
        ],
        yAxis: [
          {
            type: "value"
          }
        ]
      };
      this.charts.setOption(option);
    },
    getxAxiosData() {
      if (!this.analyses.date.length) return [];
      if (this.xAxisCaculateRangeType === this.xAxisCaculateRangeTypes.day) {
        return this.analyses.date;
      }
      const result = [];
      let firstIter = 0;
      this.anchorPoints.forEach(anchorPoint => {
        result.push(
          this.analyses.date[firstIter] +
            " ~ " +
            this.analyses.date[anchorPoint - 1]
        );
        firstIter = anchorPoint;
      });

      if (firstIter !== this.analyses.date.length - 1) {
        result.push(
          this.analyses.date[firstIter] +
            " ~ " +
            this.analyses.date[this.analyses.date.length - 1]
        );
      }
      return result;
    },
    getSeriesData(dataName, dataKey) {
      if (!this.analyses.date.length) return [];
      if (this.xAxisCaculateRangeType === this.xAxisCaculateRangeTypes.day) {
        return {
          name: dataName,
          type: "line",
          areaStyle: {
            opacity: 0.1
          },
          emphasis: {
            focus: "series"
          },
          data: this.analyses[dataKey]
        };
      }

      const result = [];
      let sum = 0.0;
      this.analyses[dataKey].forEach((value, index) => {
        if (this.anchorPoints.includes(index)) {
          result.push(sum);
          sum = 0.0;
        }
        sum = (parseFloat(sum) + parseFloat(value)).toFixed(2);
      });

      if (
        this.analyses.date.length - 1 !==
        this.anchorPoints[this.anchorPoints.length - 1]
      ) {
        result.push(sum);
      }

      return {
        name: dataName,
        type: "line",
        areaStyle: {},
        emphasis: {
          focus: "series"
        },
        data: result
      };
    },
    getAnchorPoints() {
      switch (this.xAxisCaculateRangeType) {
        case this.xAxisCaculateRangeTypes.week:
          this.getWeeklyAnchorPoints();
          break;
        case this.xAxisCaculateRangeTypes.month:
          this.getMonthlyAnchorPoints();
          break;
        case this.xAxisCaculateRangeTypes.year:
          this.getYearlyAnchorPoints();
          break;
      }
    },
    getYearlyAnchorPoints() {
      const dates = this.analyses.date;
      if (dates[0] === undefined) return;
      let year = dates[0].split("-")[0];
      dates.forEach((date, index) => {
        if (date.split("-")[0] !== year) {
          this.anchorPoints.push(index);
          year = date.split("-")[0];
        }
      });
    },
    getMonthlyAnchorPoints() {
      const dates = this.analyses.date;
      if (dates[0] === undefined) return;
      let year = dates[0].split("-")[0];
      let month = dates[0].split("-")[1];
      dates.forEach((date, index) => {
        const dateSplit = date.split("-");
        if (dateSplit[0] !== year || dateSplit[1] !== month) {
          this.anchorPoints.push(index);
          year = dateSplit[0];
          month = dateSplit[1];
        }
      });
    },
    getWeeklyAnchorPoints() {
      const dates = this.analyses.date;
      if (dates[0] === undefined) return;
      let iter = 7 - new Date(dates[0]).getDay();
      while (iter < dates.length) {
        this.anchorPoints.push(iter);
        iter += 7;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.container {
  .toeflMenu {
    margin-bottom: 15px;
  }

  .analyticsChart {
    width: 100%;
    height: 600px;
  }
}

h3 {
  margin-top: 24px;
}

.filters {
  margin: 12px 0px;
}
</style>
